import React from 'react'
import Layout from '../components/Layout'
import './pageStyles/filtration-studies.scss'
import Seo from '../components/Seo'

const FiltrationStudiesPage = () => {
  return (
    <Layout>
      <Seo title="Filtration Studies" />
      <style type="text/css" dangerouslySetInnerHTML={{ __html: "html.hs-messages-widget-open.hs-messages-mobile,html.hs-messages-widget-open.hs-messages-mobile body{overflow:hidden!important;position:relative!important}html.hs-messages-widget-open.hs-messages-mobile body{height:100%!important;margin:0!important}#hubspot-messages-iframe-container{display:initial!important;z-index:2147483647;position:fixed!important;bottom:0!important}#hubspot-messages-iframe-container.widget-align-left{left:0!important}#hubspot-messages-iframe-container.widget-align-right{right:0!important}#hubspot-messages-iframe-container.internal{z-index:1016}#hubspot-messages-iframe-container.internal iframe{min-width:108px}#hubspot-messages-iframe-container .shadow-container{display:initial!important;z-index:-1;position:absolute;width:0;height:0;bottom:0;content:\"\"}#hubspot-messages-iframe-container .shadow-container.internal{display:none!important}#hubspot-messages-iframe-container .shadow-container.active{width:400px;height:400px}#hubspot-messages-iframe-container iframe{display:initial!important;width:100%!important;height:100%!important;border:none!important;position:absolute!important;bottom:0!important;right:0!important;background:transparent!important}" }} />
      <div id="page-container" style={{ overflowY: 'hidden' }}>
        <div id="filter-boc et-boc" className="filter-boc et-boc">
          <div id="filter-main-area et-main-area">
            <div id="main-content">
              <article id="post-197" className="post-197 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="filter-l et-l filter-l--post et-l--post">
                    <div className="filter_builder_inner_content et_builder_inner_content filter_pb_gutters3 et_pb_gutters3">
                      <div className="filter_pb_section et_pb_section filter_pb_section_7 et_pb_section_7 filter_pb_with_background et_pb_with_background filter_section_regular et_section_regular section_has_divider filter_pb_bottom_divider et_pb_bottom_divider filter_pb_top_divider et_pb_top_divider optml-bg-lazyloaded filter_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: '50px' }}>
                        <div className="filter_pb_row et_pb_row filter_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                          <div className="filter_pb_column et_pb_column filter_pb_column_4_4 et_pb_column_4_4 filter_pb_column_11 et_pb_column_11  filter_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough filter-last-child et-last-child">
                            <div className="filter_pb_module et_pb_module filter_pb_text et_pb_text filter_pb_text_8 et_pb_text_8 filter_pb_text_align_center et_pb_text_align_center filter_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="filter_pb_text_inner et_pb_text_inner">Filtration Studies</div>
                            </div>
                          </div>
                        </div>
                        <div className="filter_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
                      </div>
                      <div className="filter_pb_section et_pb_section filter_pb_section_8 et_pb_section_8 filter_section_regular et_section_regular">
                        <div className="filter_pb_row et_pb_row filter_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                          <div className="filter_pb_column et_pb_column filter_pb_column_4_4 et_pb_column_4_4 filter_pb_column_12 et_pb_column_12  filter_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough filter-last-child et-last-child">
                            <div className="filter_pb_module et_pb_module filter_pb_text et_pb_text filter_pb_text_9 et_pb_text_9 filter_pb_text_align_left et_pb_text_align_left filter_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="filter_pb_text_inner et_pb_text_inner"><h2>Click any of the following links to download the PDF.</h2>
                                <ul>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133370/Arc_Medical/3M-BacterialViral-Information.pdf" target="_blank" rel="noopener noreferrer">3M: Bacterial Filtration Efficiency and Viral Filtration Efficiency Tests</a></li>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133419/Arc_Medical/cG-3M-Air-Filtration.pdf" target="_blank" rel="noopener noreferrer">circuitGuard&trade; Filter/3M Technology</a></li>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133486/Arc_Medical/3M-Filtrete-vs-TEBF.pdf" target="_blank" rel="noopener noreferrer">Comparison of 3M Filtrete&trade; Media with TEBF Media</a></li>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133545/Arc_Medical/Saykia-BFE-Nelson.pdf" target="_blank" rel="noopener noreferrer">Bacterial Filtration Efficiency Test at an Increased Challenge Level</a></li>
                                  <li><a style={{ fontSize: '14px' }} href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133581/Arc_Medical/CAMR-TB-Test.pdf" target="_blank" rel="noopener noreferrer">CAMR: Challenge Breathing System Filters against TB Aerosols</a></li>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133624/Arc_Medical/Nelson-cG-Cross-Cont.pdf" target="_blank" rel="noopener noreferrer">Nelson: Cross-Contamination Study</a></li>
                                  <li><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133667/Arc_Medical/Saykia-VFE-Nelson.pdf" target="_blank" rel="noopener noreferrer">Nelson: Virus Filtration Efficiency Test at an Increased Challenge Level</a></li>
                                </ul></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FiltrationStudiesPage